import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button';
import ReadMore from 'assets/svgs/read-more.svg';

const CLIENTS = [
	{
		name: 'Unveilinng the Potential of IT Outsourcing ',
		image: require('../../assets/images/featured-resources/unveiling.png'),
	},
	{
		name: 'Navigating the Horizon: Understanding the Impact of AI and Machine Learning',
		image: require('../../assets/images/featured-resources/navigating.png'),
	},
	{
		name: 'Flourishing in Freedom : In The Essence of Freelancing',
		image: require('../../assets/images/featured-resources/flourishing.png'),
	},
	{
		name: 'Crafting Success: Navigating the Landscape of Software Development',
		image: require('../../assets/images/featured-resources/crafting.png'),
	},
];

const FeaturedResources = () => {
	let navigate = useNavigate();
	return (
		<Row className="featured_resources">
			<Col sm={24} xl={24}>
				<Row
					justify={'center'}
					align={'middle'}
					gutter={[20, 20]}
					style={{
						margin: 0,
					}}>
					<Col sm={24} xl={16}>
						<h1 className="title">
							Featured <span>Resources</span>
						</h1>
						<p className="desc">
							Discover thought-provoking content featuring comprehensive insights, expert viewpoints, and practical wisdom. Dive into
							our blogs to stay ahead in the ever-changing tech realm
						</p>
					</Col>
					<Col span={24} className="feature_container">
						<Row
							gutter={[16, 0]}
							className="scroll_on_mobile"
							style={{
								margin: 0,
							}}>
							{CLIENTS?.map((client, index) => (
								<Col sm={24} xl={6} className="container" key={index}>
									<div className="image_name_container">
										<div className="image_container">
											<img src={client.image} alt={client.name} />
										</div>
										<div className="textArea">
											<div className="top">
												<span className="tech">Technology</span>
												<span className="time">13 Sept, 2021</span>
											</div>
											<Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className="name">
												{client.name}
											</Typography.Paragraph>
											<div className="read_more_container">
												<span className="read_more">Read More </span>
												<img src={ReadMore} alt="read more" />
											</div>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Col>
					<Col>
						<Button
							onClick={() => navigate('/blogs')}
							style={{
								borderRadius: '100px',
								minHeight: '50px',
							}}>
							View all articles
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default FeaturedResources;
