import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

export function Button() {
	return (
		<Link to="/join-with-us">
			<button className="btn">Join Our Team</button>
		</Link>
	);
}
