export const PAGE_DETAILS = {
	OUR_SERVICES: {
		ai_ml: {
			label: 'AI/ML',
			description:
				"Unlock the power of artificial intelligence and machine learning with our comprehensive services and solutions. At Upwyse, we empower businesses to harness the potential of AI and ML to drive innovation, enhance decision-making, and deliver personalized experiences. Explore how our AI/ML offerings can transform your business in today's data-driven world. Below are our services and solutions,",
			topics: [
				{
					title: 'AI/ML Consulting',
					description: 'Expert guidance and strategy development to leverage AI and ML for your specific business needs.',
					list: ['Assess business opportunities', 'Identify AI/ML use cases', 'Develop a roadmap for implementation.'],
				},
				{
					title: 'Machine Learning Model Development',
					description: 'We design, develop, and deploy custom machine-learning models tailored to your business objectives.',
					list: ['Predictive analytics', 'Recommendation systems', 'Anomaly detection and more.'],
				},
				{
					title: 'Natural Language Processing (NLP)',
					description: 'We analyze and process human language data to extract insights, sentiment analysis, and language translation.',
					list: ['Chatbots', 'Virtual assistants', 'Text analytic', 'Content categorization.'],
				},
				{
					title: 'Predictive Analytics',
					description: 'We analyze historical data to forecast future trends and outcomes, enabling data-driven decision-making.',
					list: ['Sales forecasting', 'Demand planning', 'Risk management', 'Predictive maintenance.'],
				},
				{
					title: 'Recommendation Systems',
					description: 'We personalize user experiences by developing relevant products, content, or services.',
					list: ['E-commerce product recommendations', 'Content recommendations', 'Personalized marketing.'],
				},
				{
					title: 'Anomaly Detection',
					description: 'We help you to Identify abnormal patterns or outliers in data to detect fraud, faults, or security threats.',
					list: ['Fraud detection', 'Network security', 'Predictive maintenance.', 'Quality control.'],
				},
				{
					title: 'AI-Powered Automation',
					description: 'We help you automate repetitive tasks and optimize workflows using AI-driven solutions.',
					list: ['Robotic process automation (RPA)', 'Intelligent document processing', 'Workflow optimization.'],
				},
				{
					title: 'AI Integration and Deployment',
					description: 'We help you to Integrate AI solutions seamlessly into existing systems and deploy them at scale.',
					list: ['Cloud-based AI services', 'Edge computing', 'Containerized deployments.'],
				},
				{
					title: 'AI Support and Maintenance',
					description: 'We provide Ongoing support, monitoring, and maintenance to ensure the reliability and performance of AI solutions.',
					list: ['Issue resolution', 'Performance optimization', 'System updates.'],
				},
			],
		},
		rpa: {
			label: 'Robotic process automation (RPA)',
			description:
				'Empower your organization with the efficiency and scalability of robotic process automation (RPA). At Upwyse, we offer comprehensive RPA services and solutions to automate repetitive tasks, streamline workflows, and drive digital transformation. Explore how our RPA offerings can revolutionize your business processes and enhance productivity',
			topics: [
				{
					title: 'RPA Consulting and Strategy',
					description: 'Expert guidance and strategy development to leverage AI and ML for your specific business needs.',
					list: ['Assess business opportunities', 'Prioritize automation opportunities', 'Design customized RPA strategy'],
				},
				{
					title: 'RPA Implementation and Deployment',
					description: `We design, develop, and deploy RPA bots to automate repetitive and rule-based tasks across systems
					and applications.`,
					list: [
						'Automate data entry and processing',
						'Report generation',
						'Routine tasks to improve efficiency and accuracy',
						'Content Categorization',
					],
				},
				{
					title: 'Bot Development and Customization',
					description: `We develop and customize RPA bots to meet specific business requirements and integration needs.`,
					list: ['Build bots with UI path', 'Automation anywhere', 'Blue prism tailored to your organization’s unique processes'],
				},
				{
					title: 'Integration with Enterprise Systems',
					description: `We seamlessly integrate RPA bots with existing enterprise systems, databases, and applications.`,
					list: ['Connect RPA bots with ERP,CRM,HRIS and other systems to automate end to end processes'],
				},
				{
					title: 'Cognitive Automation and AI Integration',
					description: `We enhance RPA capabilities with cognitive technologies like natural language processing (NLP) and
					machine learning (ML).`,
					list: ['Automate decision-making', 'Sentiment analysis', 'Unstructured data processing for more intelligent automation'],
				},
				{
					title: 'Process Optimization and Continuous Improvement',
					description: `We identify opportunities for process optimization and efficiency gains through ongoing analysis and
					refinement.`,
					list: ['Continuously optimize RPA workflows to adapt to changing business needs and maximize ROI'],
				},
				{
					title: 'RPA Support and Maintenance',
					description: `We provide ongoing support, troubleshooting, and maintenance services to ensure the reliability and
					performance of RPA bots.`,
					list: ['Address issues promptly', 'Apply updates', 'Scale RPA initiatives as needed'],
				},
			],
		},
		iot: {
			label: 'Internet of Things (IoT)',
			description: `Unlock the potential of the Internet of Things (IoT) with our comprehensive services and solutions. At
			Upwyse, we empower businesses to connect, monitor, and control devices and sensors in the digital
			world. Explore how our IoT offerings can drive innovation, optimize operations, and create new revenue
			streams for your organization.`,
			topics: [
				{
					title: 'IoT Strategy and Consulting',
					description: `We provide Expert guidance and strategic planning to define IoT initiatives aligned with your business
					objectives.`,
					list: ['Assess IoT opportunities', 'Identify Use cases', 'Develop a roadmap for implementation'],
				},
				{
					title: 'Sensor Networks and Connectivity',
					description: `We Deploy and manage sensor networks to collect data from physical assets and environments.`,
					list: ['Monitor environmental conditions', 'Equipment performance', 'Energy usage in real-time'],
				},
				{
					title: 'Data Analytics and Insights',
					description: `We analyze IoT data to gain actionable insights and optimize decision-making.`,
					list: ['Predictive maintenance', 'Supply chain optimization', 'Customer Behavior Analysis'],
				},
				{
					title: 'Edge Computing Solutions',
					description: `We Process and analyze IoT data closer to the source to reduce latency and bandwidth usage.`,
					list: ['Implement edge computing for real-time analytics, local decision-making and offline capabilities'],
				},
				{
					title: 'IoT Security and Privacy',
					description: `We implement robust security measures to protect IoT devices, data, and networks from cyber threats.`,
					list: ['Secure device authentication', 'Data encryption', 'Network segmentation  to mitigate risks'],
				},
				{
					title: 'Remote Monitoring and Control',
					description: `We enable remote monitoring and control of IoT devices and systems for increased efficiency and
					productivity.`,
					list: ['Monitor equipment performance', 'Adjust settings', 'Trigger alerts/actions remotely'],
				},
				{
					title: 'IoT Integration with Enterprise Systems',
					description: `We integrate IoT data with existing enterprise systems such as ERP, CRM, and BI platforms.`,
					list: ['Combine IoT insights with business data for comprehensive analysis and decision support'],
				},
				{
					title: 'IoT Application Development',
					description: `We develop custom applications and dashboards to visualize IoT data and enable user interaction.`,
					list: ['Build user-friendly interfaces for monitoring, reporting and managing IoT deployments'],
				},
				{
					title: 'Smart City and Industrial IoT (IIoT) Solutions',
					description: `We design and deploy IoT solutions for smart city initiatives, industrial automation, and smart
					infrastructure.`,
					list: ['Implement smart energy management', 'Traffic Optimization', 'Predictive Analytics for urban and industrial environments'],
				},
				{
					title: 'Predictive Maintenance Solutions',
					description: `We predict equipment failures and schedule maintenance proactively to minimize downtime and reduce
					costs.`,
					list: ['Monitor equipment health', 'Analyze performance trends', 'Generate predictive alerts'],
				},
				{
					title: 'IoT Support and Maintenance',
					description: `We provide ongoing support, troubleshooting, and maintenance services to ensure the reliability and performance of IoT deployments.`,
					list: ['Monitor device health', 'Apply software updates', 'Optimize Iot infrastructure for optimal performance'],
				},
			],
		},
		'no-code-low-code-development': {
			label: 'No-Code/Low-Code Development',
			description: `Empower your teams to build powerful applications with minimal coding using our No-Code and Low-Code development services. At Upwyse, we offer comprehensive solutions that enable rapid application
		development, streamline workflows, and drive innovation. Explore how our No-Code and Low-Code offerings can accelerate your digital transformation journey.`,
			topics: [
				{
					title: 'No-Code and Low-Code Consulting',
					description: `We provide Expert guidance and strategy development to identify use cases and adoption strategies for
					No-Code and Low-Code platforms.`,
					list: ['Assess business needs', 'Identify automation opportunities', 'Develop a roadmap for implementation'],
				},
				{
					title: 'No-Code and Low-Code Platform Selection',
					description: `We assist in selecting the right No-Code or Low-Code platform based on your organization's requirements and objectives.`,
					list: [
						'Evaluate features and capabilities',
						'Assess scalability and integration options',
						'Choose the best-fit platform for your needs',
					],
				},
				{
					title: 'Application Development and Customization',
					description: `We build custom applications and workflows using No-Code and Low-Code platforms tailored to your specific business needs.`,
					list: ['Develop web and mobile applications', 'Automate business processes', 'Customize existing applications'],
				},
				{
					title: 'Integration with Existing Systems',
					description: `We seamlessly integrate No-Code and Low-Code applications with existing enterprise systems,
					databases, and APIs.`,
					list: ['Connect applications with CRM, ERP, and BI platforms', 'Integrate with third-party services and tools'],
				},
				{
					title: 'Mobile App Development',
					description: `We Develop cross-platform mobile applications using No-Code and Low-Code platforms for iOS and
					Android devices.`,
					list: [
						'Build native and hybrid mobile apps',
						'Leverage pre-built templates and components',
						'Customize app design and functionality',
					],
				},
				{
					title: 'Process Automation and Workflow Optimization',
					description: `We automate repetitive tasks and streamline business processes using visual workflows and automation
					tools.`,
					list: ['Automate data entry and processing', 'Create approval workflows', 'Trigger notifications and alerts'],
				},
				{
					title: 'Data Visualization and Reporting',
					description: `We create interactive dashboards and reports to visualize and analyze data from multiple sources.`,
					list: ['Generate real-time insights', 'Track key performance indicators', 'Share reports with stakeholders'],
				},
				{
					title: 'Continuous Support and Maintenance',
					description: `We Provide ongoing support, troubleshooting, and maintenance services to ensure the reliability and
					performance of No-Code and Low-Code applications.`,
					list: ['Address issues promptly', 'Apply updates and patches', 'Optimize applications for performance'],
				},
			],
		},
		'full-stack-engineering': {
			label: 'Full Stack Engineering',
			description: `Full-Stack Engineering Services and Solutions by Upwyse. Accelerate your digital transformation and bring your ideas to life with our comprehensive Full-Stack
		Engineering services. At Upwyse we offer end-to-end solutions covering both frontend and backend development to create
		robust, scalable, and user-centric applications.
		Explore how our Full-Stack Engineering offerings can empower your business to innovate and thrive in
		today's competitive landscape.`,
			topics: [
				{
					title: 'Full-Stack Development',
					description: `We have expertise in both front-end and back-end development technologies to build modern, feature-rich applications.`,
					list: ['Develop web applications', 'Create mobile apps', 'Build custom software solutions'],
				},
				{
					title: 'Frontend Development',
					description: `We Design and develop intuitive user interfaces (UI) and engaging user experiences (UX) using cutting-
					edge front-end technologies.`,
					list: ['Create responsive web designs, interactive dashboards, and mobile-friendly interfaces.'],
				},
				{
					title: 'Backend Development',
					description: `We Architect and build scalable backend systems, APIs, and databases to power your applications and support business logic.`,
					list: ['Develop RESTful APIs', 'Integrate with databases and third-party services', 'Implement security measures'],
				},
				{
					title: 'Web Application Development',
					description: `We Develop custom web applications tailored to your specific business requirements, industry standards, and user needs.`,
					list: ['Build e-commerce platforms', 'Create content management systems', 'Design customer portals'],
				},
				{
					title: 'Mobile App Development',
					description: `We Design and develop native or cross-platform mobile applications for iOS and Android devices to reach a wider audience.`,
					list: ['Create consumer-facing apps, enterprise mobility solutions, and IoT (Internet of Things) integrations.'],
				},
				{
					title: 'API Development and Integration',
					description: `We Design and implement RESTful APIs and integrations to connect frontend interfaces with backend systems and third-party services.`,
					list: ['Develop custom APIs', 'Integrate with payment gateways', 'Connect with social media platforms'],
				},
				{
					title: 'Database Design and Management',
					description: `We Design and optimize database schemas, queries, and storage solutions for performance, scalability, and data integrity.`,
					list: ['Implement SQL and NoSQL databases', 'Ensure data security and compliance', 'Optimize database performance'],
				},
				{
					title: 'Cloud-Native Development',
					description: `We Architect and build cloud-native applications leveraging cloud services and platforms for scalability, reliability, and cost-effectiveness.`,
					list: ['Deploy applications on AWS, Azure, Google Cloud', 'Utilize serverless computing', 'Implement microservices architecture'],
				},
				{
					title: 'DevOps and Continuous Integration/Continuous Deployment (CI/CD)',
					description: `We Implement DevOps practices and CI/CD pipelines to automate software delivery, testing, and deployment processes.`,
					list: ['Set up version control systems', 'Automate testing and deployment', 'Monitor application performance'],
				},
				{
					title: 'UI/UX Design and Prototyping',
					description: `We Create visually appealing designs and prototypes prioritizing user needs, usability, and accessibility.`,
					list: ['Conduct user research and testing', 'Develop wireframes and mockups', 'Iterate based on feedback'],
				},
				{
					title: 'Testing and Quality Assurance (QA)',
					description: `We Perform comprehensive testing, including unit testing, integration testing, and end-to-end testing,
					to ensure application reliability and performance.`,
					list: ['Identify and fix bugs', 'Optimize application performance', 'Ensure cross-browser compatibility'],
				},
			],
		},
		'erp-solutions': {
			label: 'ERP Solutions',
			description: `Streamline your business operations and drive growth with our comprehensive ERP services. At Upwyse,
		we specialize in providing end-to-end ERP solutions tailored to meet the unique needs of your
		organization. From consultation and implementation to support and optimization, we're here to help
		you maximize the value of your ERP investment. Explore how our ERP services can transform your
		 business`,
			topics: [
				{
					title: 'ERP Consulting and Strategy',
					description: `We provide expert guidance and strategic planning to assess your business needs, select the right ERP
					solution, and develop a roadmap for implementation.`,
					list: ['Identify business objectives', 'Evaluate ERP options', 'Define a roadmap for implementation'],
				},
				{
					title: 'ERP System Integration',
					description: `We have experience in integrating ERP systems with existing enterprise applications, databases, and
					third-party solutions.`,
					list: ['Connect ERP with CRM, HRIS, and BI platforms', 'Integrate with e-commerce and supply chain systems'],
				},
				{
					title: 'ERP Implementation and Deployment',
					description: `We provide End-to-end implementation services to configure, customize, and deploy ERP software tailored to your organization's requirements.`,
					list: ['Configure modules and workflows', 'Migrate data from legacy systems', 'Train users on ERP functionality'],
				},
				{
					title: 'Customization and Development',
					description: `We customize ERP solutions to address unique business processes, industry-specific requirements, and
					regulatory compliance needs.`,
					list: ['Develop custom modules and functionalities', 'Integrate with legacy systems', 'Enhance ERP usability'],
				},
				{
					title: 'Data Migration and Conversion',
					description: `We plan and execute data migration from legacy systems to ERP platforms, ensuring data integrity and accuracy.`,
					list: ['Map data fields and structures', 'Cleanse and validate data', 'Perform test migrations'],
				},
				{
					title: 'Business Process Optimization',
					description: `We analyze existing workflows, identify inefficiencies, and redesign processes to align with ERP best practices.`,
					list: ['Streamline operations', 'Automate manual tasks', 'Improve data accuracy and consistency'],
				},
				{
					title: 'Cloud ERP Solutions',
					description: `We deploy ERP systems in the cloud for scalability, accessibility, and cost-effectiveness.`,
					list: ['Leverage cloud infrastructure', 'Enable remote access and collaboration', 'Ensure data security and compliance'],
				},
				{
					title: 'Mobile ERP Applications',
					description: `We develop mobile applications to access ERP functionality and data on the go, enabling anytime, anywhere access.`,
					list: ['Build native and hybrid mobile apps', 'Enable real-time data access', 'Support offline functionality'],
				},
				{
					title: 'Reporting and Analytics',
					description: `We implement reporting and analytics tools to provide real-time insights into key performance indicators (KPIs) and business metrics.`,
					list: ['Create dashboards and visualizations', 'Generate custom reports', 'Analyze trends and patterns'],
				},
				{
					title: 'ERP Upgrades and Enhancements',
					description: `We plan and execute ERP upgrades and enhancements to leverage new features, improve usability, and
					stay current with technology advancements.`,
					list: ['Assess upgrade requirements', 'Test new functionalities', 'Train users on new features'],
				},
			],
		},
		'content-management-system': {
			label: 'Content Management System (CMS)',
			description: `Empower your organization with a flexible and powerful Content Management System (CMS) that
		simplifies content creation, management, and delivery. At Upwyse, we offer comprehensive CMS
		services and solutions tailored to your specific needs, enabling you to create engaging digital
		experiences and drive business growth. Explore how our CMS offerings can transform your website into
		a dynamic and scalable platform for success.`,
			topics: [
				{
					title: 'CMS Consulting and Strategy',
					description: `We provide Expert guidance and strategic planning to assess your content management needs, select
					the right CMS platform, and develop a roadmap for implementation.`,
					list: ['Identify business objectives', 'Evaluate CMS options', 'Define a content strategy for optimal results.'],
				},
				{
					title: 'CMS Platform Selection and Implementation',
					description: `We Assist in selecting the most suitable CMS platform based on your requirements, industry standards,
					and budget constraints.`,
					list: [
						'Choose open-source or proprietary CMS solutions',
						'Customize CMS features and functionalities',
						'Integrate with existing systems.',
					],
				},
				{
					title: 'Custom CMS Development',
					description: `We Design and develop custom CMS solutions that align with your unique business processes, workflows, and user requirements.`,
					list: ['Build scalable and extensible CMS platforms', 'Create intuitive user interfaces', 'Optimize content workflows.'],
				},
				{
					title: 'Website Design and Development',
					description: `We Create visually stunning and user-friendly websites using CMS platforms, incorporating responsive design principles and intuitive navigation.`,
					list: ['Develop mobile-responsive websites', 'Optimize user experience and engagement', 'Ensure cross-browser compatibility.'],
				},
				{
					title: 'Theme and Plugin Development',
					description: `We Customize and extend CMS functionality with custom themes, plugins, and modules to enhance
					website aesthetics and functionality.`,
					list: ['Design unique website themes', 'Develop custom plugins and extensions', 'Optimize website performance.'],
				},
				{
					title: 'Content Migration and Data Import',
					description: `We Migrate content from legacy systems or import data from external sources into your CMS platform,
					ensuring data integrity and consistency.`,
					list: ['Map content structures', 'Cleanse and validate data', 'Perform test migrations.'],
				},
				{
					title: 'SEO Optimization and Content Strategy',
					description: `We Optimize website content for search engines and develop content strategies to improve visibility, drive traffic, and enhance user engagement.`,
					list: ['Conduct keyword research and analysis', 'Create SEO-friendly content', 'Implement on-page optimization.'],
				},
			],
		},
		'seo-digital-marketing': {
			label: 'Search Engine Optimization (SEO)',
			description: `Elevate your online presence and drive targeted traffic to your website with our comprehensive SEO
		services. At Upwyse we specialize in crafting customized SEO strategies that propel businesses to the top
		of search engine results pages (SERPs), ensuring maximum visibility and engagement. Explore how our
		SEO services and solutions can help your business succeed in the digital landscape.`,
			topics: [
				{
					title: 'SEO Audit and Analysis',
					description: `We Conduct a detailed audit of your website's SEO health, identifying strengths, weaknesses, and areas
					for improvement.`,
					list: [
						'Analyze website structure and performance',
						'Assess keyword rankings and backlink profile',
						'Identify technical issues affecting SEO.',
					],
				},
				{
					title: 'Keyword Research and Optimization',
					description: `We Identify relevant keywords and search queries with high search volume and user intent, optimizing website content accordingly.`,
					list: ['Conduct keyword research and analysis', 'Optimize on-page content', 'Create targeted landing pages.'],
				},
				{
					title: 'On-Page SEO Optimization',
					description: `We Optimize on-page elements such as titles, meta descriptions, and URLs to enhance relevance and crawlability for search engines.`,
					list: ['Optimize meta tags and headings', 'Improve internal linking structure', 'Enhance user experience.'],
				},
				{
					title: 'Technical SEO Enhancements',
					description: `We Address technical issues affecting website performance, including site speed, mobile-friendliness,
					and crawlability.`,
					list: ['Optimize website speed and performance', 'Ensure mobile responsiveness', 'Fix indexing and crawling errors.'],
				},
				{
					title: 'Content Strategy and Creation',
					description: `We Develop a content strategy aligned with your target audience's interests, creating high-quality,
					engaging content that drives organic traffic.`,
					list: ['Create blog posts, articles, and guides', 'Produce multimedia content', 'Optimize content for SEO.'],
				},
				{
					title: 'Link Building and Off-Page SEO',
					description: `We Build authoritative backlinks from reputable websites to increase domain authority and improve search engine rankings.`,
					list: ['Develop link-building strategies', 'Acquire high-quality backlinks', 'Monitor backlink profile.'],
				},
				{
					title: 'Local SEO Optimization',
					description: `We enhance your visibility in local search results and attract nearby customers with targeted local SEO
					strategies.`,
					list: ['Optimize Google My Business listing', 'Manage local citations and directories', 'Encourage customer reviews.'],
				},
				{
					title: 'SEO Performance Tracking and Reporting',
					description: `We monitor key SEO metrics, track performance trends, and provide comprehensive reporting to
					measure the effectiveness of SEO efforts.`,
					list: [
						'Analyze website traffic and conversions',
						'Monitor keyword rankings and organic search visibility',
						'Generate SEO performance reports.',
					],
				},
				{
					title: 'E-Commerce SEO Solutions',
					description: `We Optimize e-commerce websites to improve product visibility, enhance user experience, and drive sales.`,
					list: ['Optimize product pages and descriptions', 'Implement structured data markup', 'Enhance category pages.'],
				},
				{
					title: 'Continuous SEO Monitoring and Maintenance',
					description: `We Provide ongoing monitoring, maintenance, and optimization to adapt to changes in search engine algorithms and industry trends.`,
					list: ['Monitor search engine updates', 'Implement SEO best practices', 'Optimize website performance.'],
				},
			],
		},
		'e-commerce-solutions': {
			label: 'E-Commerce Solutions',
			description: `Unlock the full potential of online retail with our comprehensive e-commerce services and solutions. At
		Upwyse, we specialize in delivering end-to-end e-commerce solutions that empower businesses to
		create captivating online stores, streamline operations, and drive sales. Explore how our e-commerce
		offers can help you succeed in the digital marketplace.`,
			topics: [
				{
					title: 'E-Commerce Platform Selection and Implementation',
					description: `We Assist in selecting the right e-commerce platform based on your business needs, scalability requirements, and budget constraints.`,
					list: [
						'Choose open-source or proprietary platforms',
						'Customize platform features and functionalities',
						'Integrate with existing systems.',
					],
				},
				{
					title: 'Custom Website Design and Development',
					description: `Design and develop custom e-commerce websites that reflect your brand identity, showcase your products and provide a seamless shopping experience.`,
					list: ['Create responsive web designs', 'Optimize user experience and navigation', 'Implement secure payment gateways.'],
				},
				{
					title: 'Mobile Commerce (m-Commerce) Solutions',
					description: `We Develop mobile-responsive e-commerce websites and native mobile apps to reach customers on the
					go and maximize sales opportunities.`,
					list: ['Build mobile-friendly interfaces', 'Enable mobile payments and transactions', 'Optimize for mobile search.'],
				},
				{
					title: 'Payment Gateway Integration',
					description: `We Integrate secure payment gateways and alternative payment methods to facilitate online transactions and increase conversion rates.`,
					list: [
						'Connect with popular payment processors',
						'Implement SSL encryption for secure transactions',
						'Enable one-click checkout.',
					],
				},
				{
					title: 'Product Information Management (PIM)',
					description: `We Centralize and manage product data, attributes, and digital assets to ensure consistency, accuracy,
					and completeness across your e-commerce channels.`,
					list: ['Create product catalogs and categories', 'Manage product variants and SKUs', 'Optimize product listings.'],
				},
				{
					title: 'Inventory Management and Order Fulfillment',
					description: `We Implement inventory management systems and order fulfillment processes to optimize stock levels, reduce out-of-stock, and expedite order processing.`,
					list: [
						'Track inventory levels and reorder points',
						'Automate order processing and shipping',
						'Integrate with logistics providers.',
					],
				},
				{
					title: 'Customer Relationship Management (CRM) Integration',
					description: `We Integrate e-commerce platforms with CRM systems to centralize customer data, track interactions,and deliver personalized shopping experiences.`,
					list: ['Capture customer information and preferences', 'Automate customer communications', 'Analyze customer behavior.'],
				},
				{
					title: 'Analytics and Insights',
					description: `We Monitor key performance indicators (KPIs), track e-commerce metrics and gain actionable insights into customer behavior and sales performance.`,
					list: ['Analyze website traffic and conversions', 'Track sales and revenue', 'Optimize marketing campaigns.'],
				},
			],
		},
	},
	BLOGS: [
		{
			topic: 'Digital transformation',
			title: 'Embracing the Path of Digital Transformation',
			description: `In today's rapidly evolving business landscape, digital transformation has become a pivotal force reshaping industries, redefining operations, and revolutionizing customer experiences. Whether small startups or multinational giants, organizations worldwide are venturing into this transformative journey to maintain relevance, competitiveness, and agility in the digital era.
				Digital transformation transcends mere technology adoption; it signifies a profound shift in mindset and strategy. It entails integrating digital technologies into all facets of business operations, fundamentally altering how organizations deliver value to customers, optimize processes, and foster innovation.`,
			date: '19 feb 2024',
			latest: true,
			author: 'Upwyse',
			subTitle: 'Digital transformation transcends mere technology adoption',
			sebDescription: `It signifies a profound shift in mindset and strategy. It entails integrating digital technologies into all facets of business operations, fundamentally altering how organizations deliver value to customers, optimize processes, and foster innovation.
			Enhancing customer experiences stands as a primary catalyst for digital transformation. In today's interconnected world, consumers demand seamless, personalized interactions across various channels. Organizations must harness data analytics, AI insights, and omnichannel platforms to anticipate customer needs, offer bespoke solutions, and nurture enduring relationships.`,
			image: require('../assets/images/blogs/latest.png'),
			id: '1',
		},
		{
			topic: 'IT Outsourcing',
			title: 'Unveiling the potential of IT outsourcing',
			description: `In the rapidly evolving digital realm, IT outsourcing has become a vital strategy for businesses aiming to optimize operations, foster innovation, and maintain competitiveness. From fledgling startups to established enterprises, companies worldwide are turning to outsourcing to tap into specialized skills, bolster flexibility, and achieve cost efficiencies.`,
			date: '19 feb 2024',
			subTitle: 'IT-inhouse department',
			subDescription: `At its essence, IT outsourcing involves entrusting specific technology tasks or projects to external service providers, encompassing areas like software development, infrastructure management, cybersecurity, and technical support. This collaborative model enables businesses to access a global talent pool, leveraging top-tier expertise and resources without the overheads associated with an in-house IT department. By collaborating with seasoned vendors, organizations can benefit from domain-specific insights, industry best practices, and cutting-edge technologies that might not be readily available internally. This expertise facilitates faster project completion, risk mitigation, and the delivery of high-caliber solutions aligned with evolving customer needs.
			Moreover, IT outsourcing fosters organizational agility and adaptability. Amidst rapid market changes, outsourcing empowers businesses to scale resources according to demand fluctuations without the burden of extensive recruitment or training. This agility enables swift adaptation to market dynamics, seizing emerging opportunities and driving innovation without resource constraints.
			Cost efficiency is another significant driver of IT outsourcing. By outsourcing non-core functions, businesses can trim operational expenses, minimize capital outlay, and optimize budget allocation. Furthermore, leveraging economies of scale and competitive pricing models through outsourcing further enhances cost-effectiveness and return on investment.
			However, successful IT outsourcing necessitates meticulous planning, vendor selection, and ongoing management. Clear objectives, robust communication channels, and defined performance metrics are essential for alignment with strategic goals. Additionally, robust governance, risk management, and compliance frameworks ensure data security and regulatory adherence.
			In conclusion, IT outsourcing serves as a potent strategy for businesses seeking to enhance agility, access specialized expertise, and achieve cost savings in today's digital landscape. Embracing outsourcing as a strategic tool enables organizations to unlock growth opportunities, drive innovation, and maintain a competitive edge in an ever-evolving marketplace.
			`,
			image: require('../assets/images/blogs/unveiling.png'),
			author: 'Upwyse',
			id: '2',
		},
		{
			topic: 'Freelancing',
			title: 'Flourishing in Freedom: The Essence of Freelancing',
			description: `In today's dynamic employment landscape, freelancing has emerged as a beacon of autonomy and flexibility, offering individuals the opportunity to craft their careers according to their passions and lifestyle preferences. From creative professionals to technical experts, freelancers worldwide are embracing this liberating mode of work to achieve a balance between personal fulfillment and professional success.
			At its core, freelancing embodies the essence of freedom. It liberates individuals from the constraints of traditional employment, enabling them to define their schedules, select projects aligned with their interests, and work from anywhere with an internet connection. This autonomy empowers freelancers to prioritize their well-being, family time, and personal pursuits while pursuing meaningful work.
			`,
			date: '19 feb 2024',
			subTitle: 'IT-inhouse department',
			subDescription: `Despite these challenges, the rewards of freelancing are profound. It offers unparalleled opportunities for personal growth, professional development, and financial independence. Freelancers have the freedom to explore diverse projects, acquire new skills, and build a portfolio that reflects their expertise and passions.
			Furthermore, freelancing provides a level playing field for individuals of all backgrounds, allowing them to showcase their talents and compete based on merit rather than traditional credentials. This democratization of work opens doors for underrepresented groups, including women, minorities, and individuals from non-traditional career paths, to thrive in the global marketplace.
			In conclusion, freelancing represents more than just a career choice; it's a lifestyle that celebrates freedom, creativity, and self-determination. By embracing the opportunities and challenges of freelancing, individuals can chart their paths to success on their own terms, shaping a future where work is not just a means to an end but a fulfilling journey of growth and discovery.
			`,
			image: require('../assets/images/blogs/Flourishing.png'),
			author: 'Upwyse',
			id: '3',
		},
		{
			topic: 'Software development',
			title: 'Crafting Success: Navigating the Landscape of Software Development',
			description: `Software development stands as a cornerstone of the modern digital age, driving innovation, powering businesses, and shaping the way we interact with technology. From mobile apps to enterprise software solutions, the process of software development encompasses a multifaceted journey of creativity, collaboration, and problem-solving. In this blog, we delve into the intricacies of software development, exploring its phases, methodologies, and the key ingredients for success.
			At its core, software development involves the creation, design, implementation, testing, and maintenance of computer programs or applications. This iterative process begins with conceptualizing an idea or identifying a problem that software can address. From there, developers collaborate with stakeholders to gather requirements, define project scope, and establish objectives.
			
			However, freelancing isn't without its challenges. It requires self-discipline, resilience, and entrepreneurial spirit to navigate the uncertainties of inconsistent income, client acquisition, and project management. Freelancers must wear multiple hats, from marketing their services and negotiating contracts to managing finances and staying updated with industry trends.
			`,
			date: '19 feb 2024',
			subTitle: 'IT-inhouse department',
			subDescription: `Once the software is developed, rigorous testing is conducted to identify and resolve bugs, errors, and performance issues. Testing encompasses various methodologies, including
			unit testing, integration testing, and user acceptance testing, to ensure the software meets quality standards and functions as intended across different environments and use cases.
			Beyond development and testing, software maintenance is essential to address updates, patches, and enhancements over time. This phase involves monitoring, troubleshooting, and optimizing the software to ensure its reliability, security, and performance throughout its lifecycle.
			In the realm of software development, various methodologies guide the development process, each with its principles, practices, and suitability for different projects. Traditional methodologies like Waterfall emphasize sequential, linear progress, while Agile methodologies like Scrum and Kanban prioritize flexibility, collaboration, and incremental delivery. DevOps, on the other hand, emphasizes seamless collaboration between development and operations teams to accelerate software delivery and enhance quality.
			Moreover, the success of software development hinges not only on technical prowess but also on effective communication, collaboration, and project management. Clear communication channels, regular feedback loops, and transparent documentation foster alignment among stakeholders, minimize misunderstandings, and ensure project success.
			Furthermore, embracing innovation, continuous learning, and adaptability is paramount in the ever-evolving landscape of software development. Technologies evolve rapidly, and developers must stay abreast of emerging trends, tools, and best practices to remain competitive and deliver value to clients and end-users.
			In conclusion, software development is a dynamic and multifaceted journey characterized by creativity, collaboration, and problem-solving. By embracing best practices, methodologies, and a mindset of continuous improvement, developers can navigate the complexities of software development and craft solutions that empower businesses, delight users, and shape the digital world.
			`,
			image: require('../assets/images/blogs/crafting.png'),
			author: 'Upwyse',
			id: '4',
		},
		{
			topic: 'Product Engineering',
			title: 'Mastering the Craft: The Fusion of Creativity and Precision in Product Engineering',
			description: `Product engineering is a testament to human creativity, blending technical expertise and strategic insight to materialize groundbreaking ideas. In today's fiercely competitive market, it serves as a cornerstone for driving business growth, fostering customer satisfaction, and shaping industry landscapes. This article delves into the intricacies of product engineering, exploring its fundamental principles, intricate processes, and its transformative impact on businesses and society.
			At its core, product engineering encompasses the conception, design, development, and refinement of physical products or digital solutions tailored to address specific needs or
			solve intricate problems. Whether it's pioneering gadgets, innovative software applications, or complex systems, product engineers embark on a journey of innovation, iteration, and optimization to create solutions that resonate with users and deliver tangible value.
			The journey of product engineering commences with ideation, where teams collaborate to brainstorm ideas, identify market opportunities, and define the product vision. This phase entails comprehensive market research, user interviews, and feasibility studies to validate concepts, understand user needs, and assess technological viability.
			With a well-defined vision, product engineers proceed to the design phase, translating ideas into tangible concepts and blueprints. This stage encompasses various facets including industrial design, user experience (UX) design, and engineering design, where considerations such as aesthetics, usability, and functionality are meticulously integrated to craft compelling and intuitive products.
			Subsequently, the development phase ensues, where engineers breathe life into designs through prototyping, coding, and manufacturing. Whether it involves crafting physical prototypes or coding software applications, this stage thrives on multidisciplinary collaboration to ensure alignment with design specifications and project requirements.
			`,
			date: '19 feb 2024',
			subTitle: 'IT-inhouse department',
			subDescription: `Post-development, product engineers engage in continuous optimization and enhancement, gathering user feedback, monitoring performance metrics, and iterating on the product to elevate its value and relevance over time. This iterative approach enables adaptation to evolving market dynamics, user preferences, and technological advancements, ensuring sustained competitiveness and impact.
			Collaboration, innovation, and cross-functional teamwork serve as bedrock principles in product engineering. Product engineers collaborate closely with designers, developers, marketers, and stakeholders to align goals, leverage collective expertise, and deliver exceptional products that exceed customer expectations.
			Moreover, product engineering transcends technical execution, encompassing strategic foresight, business acumen, and a profound understanding of market dynamics and user needs. By amalgamating technical prowess with strategic vision, product engineers can craft products that not only address challenges but also inspire, delight, and catalyze meaningful change in society.
			`,
			image: require('../assets/images/blogs/mastering.png'),
			author: 'Upwyse',
			id: '5',
		},
		{
			topic: 'Artificial Engineering / Machine Learning',
			title: 'Navigating the Horizon: Understanding the Impact of AI and Machine Learning',
			description: `In today's swiftly evolving technological landscape, Artificial Intelligence (AI) and Machine Learning (ML) have emerged as powerful catalysts for change, reshaping industries, redefining business paradigms, and fundamentally altering the way we interact with technology. From personalized recommendations to autonomous vehicles, the applications of AI and ML are ubiquitous, promising to unlock new possibilities, drive efficiencies, and propel society into an era fueled by intelligent automation and data-driven decision-making. In this blog, we embark on a journey to uncover the transformative potential of AI and ML, exploring their underlying principles, real-world applications, and the profound impact they have on businesses and society.`,
			date: '19 feb 2024',
			subTitle: 'IT-inhouse department',
			subDescription: `At its essence, AI involves the development of computer systems capable of performing tasks typically requiring human intelligence, such as reasoning, problem-solving, and learning from experience. ML, a subset of AI, focuses on developing algorithms enabling computers to learn patterns and insights from data, enhancing performance and making predictions without explicit programming.
			The convergence of AI and ML holds immense promise across various industries, revolutionizing processes, enhancing customer experiences, and driving innovation. In healthcare, AI-powered diagnostic tools analyze medical images, detect anomalies, and aid clinicians in making accurate diagnoses, thereby improving patient outcomes and expediting treatment decisions. In finance, ML algorithms analyze extensive datasets to detect fraud, optimize trading strategies, and personalize financial recommendations, enabling organizations to mitigate risks and capitalize on market opportunities.
			Furthermore, AI and ML are transforming the way businesses operate, enabling automation, predictive analytics, and personalized experiences at scale. AI-driven chatbots provide instant customer support, streamline inquiries, and enhance engagement, while recommendation engines analyze user behavior to deliver tailored content and product suggestions, thereby boosting conversion rates and fostering customer loyalty.
			`,
			image: require('../assets/images/blogs/navigating.png'),
			author: 'Upwyse',
			id: '6',
		},
		{
			topic: 'Technology',
			title: 'Technology: A Double-Edged Sword - Unveiling Its Boons and Baneful Effects',
			description: `Technology, with its ever-evolving advancements, has become an integral part of our daily lives, revolutionizing how we communicate, work, and interact with the world around us. From the convenience of instant communication to the power of vast information at our fingertips, technology has undoubtedly brought about significant benefits to society. However, alongside its boons, technology also harbors a darker side, presenting a myriad of challenges and potential harm. In this blog, we delve into the dichotomy of technology, exploring its blessings and banes, and the pivotal role it plays in shaping our modern world.
			`,
			date: '19 feb 2024',
			subTitle: 'IT-inhouse department',
			subDescription: `Boon: Connectivity and Communication
			One of the most profound boons of technology is its ability to connect people across vast distances and facilitate instant communication. Platforms such as social media, messaging apps, and video conferencing have transformed the way we interact, bridging geographical barriers and fostering global connectivity. Through technology, we can stay connected with loved ones, collaborate with colleagues remotely, and access information from anywhere in the world, enriching our personal and professional lives in unprecedented ways.
			
			Bane: Social Isolation and Digital Addiction
			However, the relentless pursuit of digital connectivity has also led to concerns regarding social isolation and digital addiction. Excessive use of social media and technology can lead to a decline in face-to-face interactions, eroding social bonds and exacerbating feelings of loneliness and isolation. Moreover, the addictive nature of digital devices and platforms can contribute to diminished productivity, poor mental health, and a sense of disconnection from the real world.
			
			Boon: Access to Information and Education
			Technology has democratized access to information, empowering individuals with knowledge and educational resources previously inaccessible to many. The internet serves as a vast repository of information, offering limitless opportunities for learning, research,
			and self-improvement. Online courses, educational apps, and digital libraries have revolutionized the way we learn, enabling lifelong learning and skill development irrespective of geographical location or socioeconomic status.
			
			Bane: Misinformation and Digital Divide
			However, the proliferation of information on the internet has also given rise to misinformation, fake news, and digital echo chambers. The ease of sharing information online, coupled with the lack of accountability, has fueled the spread of falsehoods and conspiracy theories, undermining trust in traditional media and institutions. Moreover, the digital divide exacerbates inequalities in access to information, with marginalized communities facing barriers to digital literacy and internet connectivity.
			
			Boon: Efficiency and Innovation
			Technology has driven unprecedented levels of efficiency and innovation across various industries, revolutionizing business processes, enhancing productivity, and fueling economic growth. Automation, artificial intelligence, and data analytics have streamlined operations, optimized supply chains, and unlocked new opportunities for innovation and entrepreneurship. From smart factories to autonomous vehicles, technology continues to push the boundaries of what is possible, reshaping industries and driving progress.
			
			Bane: Job Displacement and Economic Inequality
			However, the rapid pace of technological advancement has also led to concerns regarding job displacement and economic inequality. Automation and artificial intelligence threaten to disrupt traditional industries and displace millions of jobs, leading to unemployment and economic uncertainty for many workers. Moreover, the benefits of technological progress are not distributed equally, exacerbating income inequality and widening the gap between the rich and the poor.
			
			Boon: Healthcare and Well-being
			Technology has revolutionized healthcare, improving diagnosis, treatment, and patient care in unprecedented ways. From telemedicine and wearable devices to medical imaging and precision medicine, technology has transformed the healthcare landscape, enabling early detection of diseases, personalized treatment plans, and remote monitoring of patients. Moreover, health and wellness apps empower individuals to take control of their well-being, track fitness goals, and access mental health support from the comfort of their smartphones.
			
			Bane: Privacy Concerns and Surveillance
			However, the digitization of healthcare also raises concerns regarding privacy and data security. The proliferation of electronic health records and wearable devices has led to unprecedented amounts of personal health data being collected and stored by healthcare providers and tech companies. This has sparked concerns regarding data privacy, security
			breaches, and the potential for surveillance and exploitation of sensitive medical information.`,
			image: require('../assets/images/blogs/technology.png'),
			author: 'Upwyse',
			id: '7',
		},
	],
};
