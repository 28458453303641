import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button';

const LeadingCompanies = () => {
	let navigate = useNavigate();
	return (
		<Col span={24}>
			<Row className="leading_companies">
				<Col sm={24} xl={8} className="left_area">
					<img className="image" src={require('../../assets/images/leading.png')} alt="leading companies" />
				</Col>
				<Col sm={24} xl={2} />
				<Col sm={24} xl={14} className="right_area">
					<Row>
						<Col sm={24} xl={14}>
							<div className="text_area">
								<div className="title">
									<h1>
										Leading companies trust us <span>for IT solutions</span>
									</h1>
								</div>
								<p className="desc">
									We at Upwyse seamlessly blend creativity and technology, dedicated to transforming every idea into a pioneering
									reality through tailored solutions that leverage the latest advancements for each client's unique needs
								</p>
								<Button onClick={() => navigate('/about')}>Know More</Button>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default LeadingCompanies;
