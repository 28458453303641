import React from 'react';
import { Row, Col } from 'antd';
import Footer from 'components/footer';
import BannerSection from 'components/top-section';
import LeadingCompanies from './leading-companies';
import OurClients from './our-clients';
import WhyChoose from './why-choose';
import FeaturedResources from './featured-resource';
import ToSupportUs from './to-support-us';
import OurServices from './our-services';
import './home.scss';

export default function Home() {
	return (
		<Row justify={'center'}>
			<BannerSection
				isHome
				title="From Ideas to Implementation"
				bannerText="Blending creativity and technology to boost business growth"
				animatedText
			/>
			<Col span={20}>
				<LeadingCompanies />
			</Col>
			<Col xl={20} sm={24}>
				<OurServices />
			</Col>
			<Col xl={20} sm={24}>
				<WhyChoose />
			</Col>
			<OurClients />
			<Col xl={20} sm={24}>
				<ToSupportUs />
			</Col>
			<Col xl={20} sm={24}>
				<FeaturedResources />
			</Col>
			<Footer />
		</Row>
	);
}
