import React from 'react';
import { Col, Row } from 'antd';
import Footer from 'components/footer';
import BannerSection from 'components/top-section';
import OurMission from './out-mission';
import './about.scss';
import OurVision from './out-vision';
import CoreValues from './core-values';
import EngagementModel from './engagement-model';
import OurApproach from './our-approach';

const About = () => {
	return (
		<Row justify={'center'}>
			<Col span={24}>
				<BannerSection
					isAbout
					title="About us"
					desc="We at Upwyse seamlessly blend creativity and technology, dedicated to transforming every idea into a pioneering reality through tailored solutions that leverage the latest advancements for each client's unique needs."
				/>
			</Col>
			<Col span={20}>
				<OurMission />
				<OurVision />
				<CoreValues />
				<EngagementModel />
				<OurApproach />
			</Col>
			<Col span={24}>
				<Footer />
			</Col>
		</Row>
	);
};

export default About;
