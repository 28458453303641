import React from 'react';
import { Col, Row } from 'antd';
import ReactCardFlip from 'react-card-flip';
import Location from '../../assets/svgs/to-support-us/location.svg';
import Chennai from '../../assets/svgs/Chennai.svg';
import Project from '../../assets/svgs/to-support-us/project.svg';
import Region from '../../assets/svgs/to-support-us/region.svg';
import Industries from '../../assets/svgs/to-support-us/industry.svg';
import Technology from '../../assets/svgs/to-support-us/technology.svg';

const CLIENTS = [
	{
		name: 'Location',
		logo: Location,
		count: 100,
		icon: Chennai,
		desc: 'Chennai, India',
	},
	{
		name: 'Projects',
		logo: Project,
		count: 53,
		desc: 'Successfully Delivered',
	},
	{
		name: 'Regions',
		logo: Region,
		count: 3,
		desc: 'Clients we served',
	},
	{
		name: 'Industries',
		logo: Industries,
		count: 8,
		desc: 'Industries we served',
	},
	{
		name: 'Technology',
		logo: Technology,
		count: 40,
		desc: 'Technologies we are experts at',
	},
];
const FlippableCard = ({ client }) => {
	const [isFlipped, setIsFlipped] = React.useState(false);
	const [flipTimeout, setFlipTimeout] = React.useState(null);

	const handleMouseEnter = () => {
		const timeout = setTimeout(() => {
			setIsFlipped(true);
		}, 200);
		setFlipTimeout(timeout);
	};

	const handleMouseLeave = () => {
		clearTimeout(flipTimeout);
		setIsFlipped(false);
	};

	return (
		<ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
			<div key="front" onMouseEnter={handleMouseEnter} className="front">
				<div className="container">
					<div className="image_container">
						<img src={client.logo} alt={client.name} />
						<h3 className="name">{client.name}</h3>
					</div>
				</div>
			</div>

			<div key="back" onMouseLeave={handleMouseLeave} className="back">
				<div className="container">
					<div className="back_image_container">
						{client?.icon ? (
							<img src={client?.icon} alt={client.name} />
						) : (
							<h3 className="count">
								{client.count < 10 ? '0' : ''}
								{client.count}+
							</h3>
						)}
						<span className="desc">{client?.desc}</span>
					</div>
				</div>
			</div>
		</ReactCardFlip>
	);
};

const ToSupportUs = () => {
	return (
		<Row className="to_support_us">
			<Col sm={24} xl={24}>
				<Row justify={'center'} align={'middle'}>
					<Col sm={24} xl={16}>
						<h1 className="title">
							Some <span>numbers</span> to <span>support us</span>
						</h1>
						<p className="desc">
							Empowering Success Through Data: Our numbers reflect our expertise and commitment, validating our track record of success
						</p>
					</Col>
					<Col span={24} className="feature_container">
						{CLIENTS?.map((client, index) => (
							<FlippableCard key={index} client={client} />
						))}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ToSupportUs;
