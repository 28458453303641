import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
// import Button from 'components/button';
import ArrowRight from 'assets/svgs/arrow-right.svg';

const SERVICES = [
	{
		name: 'AI/ML',
		desc: 'Unlock the power of artificial intelligence and machine learning with our comprehensive services and solutions',
		image: require('../../assets/images/featured-resources/unveiling.png'),
		path: '/ai_ml',
	},
	{
		name: 'RPA',
		desc: 'Empower your organization with the efficiency and scalability of robotic process automation',
		image: require('../../assets/images/featured-resources/navigating.png'),
		path: '/rpa',
	},
	{
		name: 'IoT (Internet of Things)',
		desc: 'We provide Expert guidance and strategic planning to define IoT initiatives aligned with your business objectives',
		image: require('../../assets/images/featured-resources/flourishing.png'),
		path: '/iot',
	},
	{
		name: 'No-Code & Low-Code',
		desc: 'We provide Expert guidance and strategic planning to define IoT initiatives aligned with your business objectives',
		image: require('../../assets/images/featured-resources/crafting.png'),
		path: '/no-code-low-code-development',
	},
	{
		name: 'Full-Stack Engineering',
		desc: 'Accelerate your digital transformation and bring your ideas to life with our comprehensive Full-Stack Engineering services',
		image: require('../../assets/images/featured-resources/crafting.png'),
		path: '/full-stack-engineering',
	},
	{
		name: 'ERP Solutions',
		desc: 'Streamline your business operations and drive growth with our comprehensive ERP services',
		image: require('../../assets/images/featured-resources/crafting.png'),
		path: '/erp-solutions',
	},
	{
		name: 'CMS',
		desc: 'Empower your organization with a flexible and powerful Content Management System (CMS) that simplifies content creation, management, and delivery',
		image: require('../../assets/images/featured-resources/crafting.png'),
		path: '/content-management-system',
	},
	{
		name: 'SEO',
		desc: 'Elevate your online presence and drive targeted traffic to your website with our comprehensive Search Engine Optimization services.',
		image: require('../../assets/images/featured-resources/crafting.png'),
		path: '/seo-digital-marketing',
	},
	{
		name: 'E-Commerce Solutions',
		desc: 'Unlock the full potential of online retail with our comprehensive e-commerce services and solutions',
		image: require('../../assets/images/featured-resources/crafting.png'),
		path: '/e-commerce-solutions',
	},
];

const OurServices = () => {
	let navigate = useNavigate();
	return (
		<Row className="our_services">
			<Col sm={24} xl={24}>
				<Row
					justify={'center'}
					align={'middle'}
					gutter={[20, 20]}
					style={{
						margin: 0,
					}}>
					<Col sm={24} xl={16}>
						<h1 className="title">
							<span>Services</span> that lead the way to <span>better business</span>
						</h1>
						<p className="desc">
							We specialize in turning concepts into impactful digital journeys that resonate and motivate, reshaping digital potentials
							through a wide range of services
						</p>
					</Col>
					<Col span={24} className="feature_container">
						<Row gutter={[16, 0]} className="scroll_on_mobile">
							{SERVICES?.map((service, index) => (
								<Col sm={24} xl={8} className="container" key={index} onClick={() => navigate(`/services${service.path}`)}>
									<div className="text_container">
										<h2 className="title">{service?.name}</h2>
										<span className="description">{service?.desc}</span>
										<div className="arrow">
											<img src={ArrowRight} alt={service?.name} />
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Col>
					{/* <Col>
						<Button
							style={{
								borderRadius: '100px',
								minHeight: '50px',
								padding: '0 20px',
							}}>
							View all articles
						</Button>
					</Col> */}
				</Row>
			</Col>
		</Row>
	);
};

export default OurServices;
