import React from 'react';
import { Col, Row } from 'antd';
import Marquee from 'react-fast-marquee';
import BigPay from '../../assets/svgs/clients/Bigpay.svg';
// import Client6 from '../../assets/svgs/clients/client6.svg';
import Edtera from '../../assets/svgs/clients/Edtera.svg';
import EpitronX from '../../assets/svgs/clients/EpitronX.svg';
import Heromed from '../../assets/svgs/clients/Heromed.svg';
import Izmirlian from '../../assets/svgs/clients/Izmirlian.svg';
import Jumeirah from '../../assets/svgs/clients/Jumeirah.svg';
import Mutare from '../../assets/svgs/clients/Mutare.svg';
import Naruvi from '../../assets/svgs/clients/Naruvi.svg';
import Points from '../../assets/svgs/clients/Points.svg';
import Rcos from '../../assets/svgs/clients/Rcos.svg';
import Satyaspecialschool from '../../assets/svgs/clients/Satyaspecialschool.svg';
import Touch4bill from '../../assets/svgs/clients/Touch4bill.svg';
import urumee from '../../assets/svgs/clients/urumee.svg';

const CLIENTS = [
	{
		logo: BigPay,
	},
	// {
	// 	logo: Client6,
	// },
	{
		logo: Edtera,
	},
	{
		logo: EpitronX,
	},
	{
		logo: Heromed,
	},
	{
		logo: Izmirlian,
	},
	{
		logo: Jumeirah,
	},
	{
		logo: Mutare,
	},
	{
		logo: Naruvi,
	},
	{
		logo: Points,
	},
	{
		logo: Rcos,
	},
	{
		logo: Satyaspecialschool,
	},
	{
		logo: Touch4bill,
	},
	{
		logo: urumee,
	},
];

const OurClients = () => {
	return (
		<Row className="our_clients">
			<Col sm={24} xl={24}>
				<Row justify={'center'} align={'middle'}>
					<Col sm={24} xl={16}>
						<h1 className="title">
							<span>Meet</span> Our Clients
						</h1>
						<p className="desc">
							Our commitment goes beyond words. We have reshaped the narrative for several businesses like yours with robust digital
							solutions
						</p>
					</Col>
					<Col span={24} className="marquee_container">
						<Marquee>
							{CLIENTS?.map((client, index) => (
								<div className="image_container" key={index}>
									<img src={client.logo} alt={index} key={index} />
								</div>
							))}
						</Marquee>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default OurClients;
