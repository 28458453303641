import React from 'react';
import { Col, Row } from 'antd';
import BannerSection from 'components/top-section';
import Footer from 'components/footer';
import HTML from 'assets/svgs/technology/html.svg';
import CSS from 'assets/svgs/technology/css.svg';
import JS from 'assets/svgs/technology/js.svg';
import AdobeXD from 'assets/svgs/technology/UIUX Design/Tools/XD.svg';
import Figma from 'assets/svgs/technology/figma.svg';
import Invision from 'assets/svgs/technology/invision.svg';
import Photoshop from 'assets/svgs/technology/photoshop.svg';
import Illustrator from 'assets/svgs/technology/illustator.svg';
import AfterEffects from 'assets/svgs/technology/aftereffects.svg';

// Mobile Application Development
import AndroidPlatform from 'assets/svgs/technology/Mobile App Dev/Platforms/Android.svg';
import IOSPlatform from 'assets/svgs/technology/Mobile App Dev/Platforms/IOS.svg';
import Swift from 'assets/svgs/technology/Mobile App Dev/Languages/Swift.svg';
import Kotlin from 'assets/svgs/technology/Mobile App Dev/Languages/Kotlin.svg';
import Java from 'assets/svgs/technology/Mobile App Dev/Languages/Java.svg';
import JavaScript from 'assets/svgs/technology/Mobile App Dev/Languages/JS.svg';
import Flutter from 'assets/svgs/technology/Mobile App Dev/Frameworks/Flutter.svg';
import ReactNative from 'assets/svgs/technology/Mobile App Dev/Frameworks/React.svg';
import Xamarin from 'assets/svgs/technology/Mobile App Dev/Frameworks/Xamarin.svg';

// Open-Source Technology
import NodeJs from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/NodeJs.svg';
import JavaOpenSource from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/Java.svg';
import Python from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/Python.svg';
import Ruby from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/Ruby.svg';
import ReactOpenSource from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/React.svg';
import Angular from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/Angular.svg';
import Vuejs from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/Vuejs.svg';
import Django from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/DJ.svg';
// import XamarinOpenSource from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/xamarin.svg';
import XamarinOpenSource from 'assets/svgs/technology/Mobile App Dev/Frameworks/Xamarin.svg';
import Go from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/GO.svg';
import Laravel from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/Laravel.svg';
import Jquery from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/Jquery.svg';
import PHP from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/PHP.svg';
import DotNet from 'assets/svgs/technology/Open-source technology/Languages & Frameworks/DotNet.svg';
import Apache from 'assets/svgs/technology/Open-source technology/Server & Database/Apache.svg';
import MySQL from 'assets/svgs/technology/Open-source technology/Server & Database/MySQL.svg';
import MongoDB from 'assets/svgs/technology/Open-source technology/Server & Database/MongoDB.svg';
import PostgreSQL from 'assets/svgs/technology/Open-source technology/Server & Database/PostgreSQL.svg';
import Oracle from 'assets/svgs/technology/Open-source technology/Server & Database/Oracle.svg';
import Ngnix from 'assets/svgs/technology/Open-source technology/Server & Database/Ngnix.svg';
import Wordpress from 'assets/svgs/technology/Open-source technology/Platforms/wordpress.svg';
import Drupal from 'assets/svgs/technology/Open-source technology/Platforms/Drupal.svg';
import Magento from 'assets/svgs/technology/Open-source technology/Platforms/Magento.svg';
import Shopify from 'assets/svgs/technology/Open-source technology/Platforms/Shopify.svg';
import Salesforce from 'assets/svgs/technology/Open-source technology/Platforms/Salesforce.svg';

// Microsoft Technologies
import CSharp from 'assets/svgs/technology/Microsoft technologies/Languages/Csharp.svg';
import VBNet from 'assets/svgs/technology/Microsoft technologies/Languages/vbnetp1.svg';
import DevExpress from 'assets/svgs/technology/Microsoft technologies/Languages/devexpress.svg';
import VisualBasic from 'assets/svgs/technology/Microsoft technologies/Languages/Visualbasics.svg';
import ExtNet from 'assets/svgs/technology/Microsoft technologies/Languages/Extnet1.svg';
import VSCode from 'assets/svgs/technology/Microsoft technologies/Languages/VScode.svg';
import EntityFramework from 'assets/svgs/technology/Microsoft technologies/Frameworks/Entityframeworks.svg';
import NetMVC from 'assets/svgs/technology/Microsoft technologies/Frameworks/netmvc.svg';
import WebAPI from 'assets/svgs/technology/Microsoft technologies/Frameworks/webapi.svg';
import WCF from 'assets/svgs/technology/Microsoft technologies/Frameworks/wincommunicationfoundation.svg';
import XamarinMicrosoft from 'assets/svgs/technology/Microsoft technologies/Platforms/Xamarin.svg';
import NetCore from 'assets/svgs/technology/Microsoft technologies/Platforms/netcore.svg';
import VisualStudio from 'assets/svgs/technology/Microsoft technologies/Database & Reporting Tool/Visualstudio.svg';
import VSS from 'assets/svgs/technology/Microsoft technologies/Database & Reporting Tool/VSS.svg';
import MicroSQLServer from 'assets/svgs/technology/Microsoft technologies/Database & Reporting Tool/microsqlserver.svg';
import Git from 'assets/svgs/technology/Microsoft technologies/Database & Reporting Tool/Git.svg';
import PowerBi from 'assets/svgs/technology/Microsoft technologies/Database & Reporting Tool/PowerBi.svg';

// Quality Analyst
import JavaQA from 'assets/svgs/technology/Quality Analyst/Languages/Java.svg';
import JavaScriptQA from 'assets/svgs/technology/Quality Analyst/Languages/JS.svg';
import TestFramework from 'assets/svgs/technology/Quality Analyst/Frameworks/Test.svg';
import DataDriven from 'assets/svgs/technology/Quality Analyst/Frameworks/DataDriven.svg';
import TestNG from 'assets/svgs/technology/Quality Analyst/Frameworks/TestNG.svg';
import Junit from 'assets/svgs/technology/Quality Analyst/Frameworks/Junit.svg';
import PHPUnit from 'assets/svgs/technology/Quality Analyst/Frameworks/phpunit.svg';
import Cucumber from 'assets/svgs/technology/Quality Analyst/Frameworks/cucumber.svg';
import Selenium from 'assets/svgs/technology/Quality Analyst/Tools/Selenium.svg';
import SeleniumGrid from 'assets/svgs/technology/Quality Analyst/Tools/Seleniumgrid.svg';
import JMeter from 'assets/svgs/technology/Quality Analyst/Tools/jmeter.svg';
import BrowserStack from 'assets/svgs/technology/Quality Analyst/Tools/browserstack.svg';
import Appium from 'assets/svgs/technology/Quality Analyst/Tools/appium.svg';
import LoadRunner from 'assets/svgs/technology/Quality Analyst/Tools/loadrunner.svg';
import Katalon from 'assets/svgs/technology/Quality Analyst/Tools/Katalon.svg';
import Cypress from 'assets/svgs/technology/Quality Analyst/Tools/Cypress.svg';
import JMeterReport from 'assets/svgs/technology/Quality Analyst/Reports/jmeter.svg';
import ExtentReport from 'assets/svgs/technology/Quality Analyst/Reports/extentreport.svg';

// Cloud Services
import MicrosoftAzure from 'assets/svgs/technology/Cloud services/Hosting/MicrosoftAzure.svg';
import AWS from 'assets/svgs/technology/Cloud services/Hosting/AWS.svg';
import GCP from 'assets/svgs/technology/Cloud services/Hosting/GCP.svg';

// No-code/Low-code Development
import Bubble from 'assets/svgs/technology/No-code & Low-code/Tools/bubble.svg';
import Webflow from 'assets/svgs/technology/No-code & Low-code/Tools/webflow.svg';
import Outsystems from 'assets/svgs/technology/No-code & Low-code/Tools/outsystems.svg';
import MicrosoftPowerApps from 'assets/svgs/technology/No-code & Low-code/Tools/microsoftpowerapps.svg';

// ERP Solutions
import SAP from 'assets/svgs/technology/ERP solutions/Tools/SAP.svg';
import OracleERP from 'assets/svgs/technology/ERP solutions/Tools/oracle.svg';
import MicrosoftD365 from 'assets/svgs/technology/ERP solutions/Tools/microd365.svg';
import Netsuite from 'assets/svgs/technology/ERP solutions/Tools/netsuite.svg';

import TechnologyShowcase from './technology-showcase';
import './technology.scss';

const DATA = [
	{
		firstText: 'UI/UX',
		secondText: 'Design',
		description:
			'Elevate user satisfaction with our comprehensive UI/UX solutions. From intuitive designs to seamless interactions, we specialize in creating captivating digital experiences.',
		list: [
			{
				title: 'Languages',
				data: [
					{
						name: 'HTML',
						icon: HTML,
					},
					{
						name: 'CSS',
						icon: CSS,
					},
					{
						name: 'JavaScript',
						icon: JS,
					},
				],
			},
			{
				title: 'Tools',
				data: [
					{
						name: 'Adobe XD',
						icon: AdobeXD,
					},
					{
						name: 'Figma',
						icon: Figma,
					},
					{
						name: 'Invision',
						icon: Invision,
					},
					{
						name: 'Abode Photoshop',
						icon: Photoshop,
					},
					{
						name: 'Abode Illustrator',
						icon: Illustrator,
					},
					{
						name: 'After Effects',
						icon: AfterEffects,
					},
				],
			},
		],
	},
	{
		firstText: 'Mobile Application',
		secondText: 'Development',
		highlight: 2,
		description:
			'Unlock the potential of your business with our advanced mobile application development services. With expertise from conceptualization to launch, we specialize in crafting innovative apps customized to your requirements.',
		list: [
			{
				title: 'Platforms',
				data: [
					{
						name: 'Android',
						icon: AndroidPlatform,
					},
					{
						name: 'IOS',
						icon: IOSPlatform,
					},
				],
			},
			{
				title: 'Languages',
				data: [
					{
						name: 'Swift',
						icon: Swift,
					},
					{
						name: 'Kotlin',
						icon: Kotlin,
					},
					{
						name: 'Java',
						icon: Java,
					},
					{
						name: 'JavaScript',
						icon: JavaScript,
					},
				],
			},
			{
				title: 'Frameworks',
				data: [
					{
						name: 'Flutter',
						icon: Flutter,
					},
					{
						name: 'React Native',
						icon: ReactNative,
					},
					{
						name: 'Xamarin',
						icon: Xamarin,
					},
				],
			},
		],
	},
	{
		firstText: 'Open-Source',
		secondText: 'Technology',
		highlight: 1,
		description:
			'Explore the potential of open-source technology through our all-encompassing services. With expertise in implementation and optimization, we excel in leveraging open-source solutions customized to your business requirements.',
		list: [
			{
				title: 'Languages & Frameworks',
				data: [
					{
						name: 'NodeJs',
						icon: NodeJs,
					},
					{
						name: 'Java',
						icon: JavaOpenSource,
					},
					{
						name: 'Python',
						icon: Python,
					},
					{
						name: 'Ruby',
						icon: Ruby,
					},
					{
						name: 'React',
						icon: ReactOpenSource,
					},
					{
						name: 'Angular',
						icon: Angular,
					},
					{
						name: 'Vuejs',
						icon: Vuejs,
					},
					{
						name: 'Django',
						icon: Django,
					},
					{
						name: 'Xamarin',
						icon: XamarinOpenSource,
					},
					{
						name: 'Go',
						icon: Go,
					},
					{
						name: 'Laravel',
						icon: Laravel,
					},
					{
						name: 'Jquery',
						icon: Jquery,
					},
					{
						name: 'PHP',
						icon: PHP,
					},
					{
						name: 'DotNet',
						icon: DotNet,
					},
				],
			},
			{
				title: 'Server & Database',
				data: [
					{
						name: 'Apache',
						icon: Apache,
					},
					{
						name: 'MySQL',
						icon: MySQL,
					},
					{
						name: 'MongoDB',
						icon: MongoDB,
					},
					{
						name: 'PostgreSQL',
						icon: PostgreSQL,
					},
					{
						name: 'Oracle',
						icon: Oracle,
					},
					{
						name: 'Ngnix',
						icon: Ngnix,
					},
				],
			},
			{
				title: 'Platforms',
				data: [
					{
						name: 'Wordpress',
						icon: Wordpress,
					},
					{
						name: 'Drupal',
						icon: Drupal,
					},
					{
						name: 'Magento',
						icon: Magento,
					},
					{
						name: 'Shopify',
						icon: Shopify,
					},
					{
						name: 'Salesforce',
						icon: Salesforce,
					},
				],
			},
		],
	},
	{
		firstText: 'Microsoft',
		secondText: 'Technologies',
		highlight: 1,
		description:
			'Utilize Microsoft services to strengthen your business. We excel in optimizing digital infrastructure, from cloud solutions to productivity tools.',
		list: [
			{
				title: 'Languages',
				data: [
					{
						name: 'C#',
						icon: CSharp,
					},
					{
						name: 'VB.Net',
						icon: VBNet,
					},
					{
						name: 'DevExpress',
						icon: DevExpress,
					},
					{
						name: 'Visual Basic',
						icon: VisualBasic,
					},
					{
						name: 'Ext.Net',
						icon: ExtNet,
					},
					{
						name: 'VS Code',
						icon: VSCode,
					},
				],
			},
			{
				title: 'Frameworks',
				data: [
					{
						name: 'Entity Framework',
						icon: EntityFramework,
					},
					{
						name: '.Net MVC',
						icon: NetMVC,
					},
					{
						name: 'Web API',
						icon: WebAPI,
					},
					{
						name: 'WCF',
						icon: WCF,
					},
				],
			},
			{
				title: 'Platforms',
				data: [
					{
						name: 'Xamarin',
						icon: XamarinMicrosoft,
					},
					{
						name: '.Net Core',
						icon: NetCore,
					},
				],
			},
			{
				title: 'Database & Reporting Tool',
				data: [
					{
						name: 'Visual Studio',
						icon: VisualStudio,
					},
					{
						name: 'VSS',
						icon: VSS,
					},
					{
						name: 'MicroSQL Server',
						icon: MicroSQLServer,
					},
					{
						name: 'Git',
						icon: Git,
					},
					{
						name: 'Power BI',
						icon: PowerBi,
					},
				],
			},
		],
	},
	{
		firstText: 'Quality',
		secondText: 'Analyst',
		highlight: 1,
		description: `Enhance your product's quality with our premium QA services. With thorough testing and precise analysis, we guarantee flawless performance and user delight.`,
		list: [
			{
				title: 'Languages',
				data: [
					{
						name: 'Java',
						icon: JavaQA,
					},
					{
						name: 'JavaScript',
						icon: JavaScriptQA,
					},
				],
			},
			{
				title: 'Frameworks',
				data: [
					{
						name: 'Test Framework',
						icon: TestFramework,
					},
					{
						name: 'Data Driven',
						icon: DataDriven,
					},
					{
						name: 'TestNG',
						icon: TestNG,
					},
					{
						name: 'Junit',
						icon: Junit,
					},
					{
						name: 'PHPUnit',
						icon: PHPUnit,
					},
					{
						name: 'Cucumber',
						icon: Cucumber,
					},
				],
			},
			{
				title: 'Tools',
				data: [
					{
						name: 'Selenium',
						icon: Selenium,
					},
					{
						name: 'Selenium Grid',
						icon: SeleniumGrid,
					},
					{
						name: 'JMeter',
						icon: JMeter,
					},
					{
						name: 'BrowserStack',
						icon: BrowserStack,
					},
					{
						name: 'Appium',
						icon: Appium,
					},
					{
						name: 'Load Runner',
						icon: LoadRunner,
					},
					{
						name: 'Katalon',
						icon: Katalon,
					},
					{
						name: 'Cypress',
						icon: Cypress,
					},
				],
			},
			{
				title: 'Reports',
				data: [
					{
						name: 'JMeter Report',
						icon: JMeterReport,
					},
					{
						name: 'Extent Report',
						icon: ExtentReport,
					},
				],
			},
		],
	},
	{
		firstText: 'Cloud',
		secondText: 'Services',
		highlight: 1,
		description:
			'Discover seamless scalability and unwavering reliability with our cloud services. With expertise in migration, management, and hosting, we provide tailored solutions to enhance your digital infrastructure.',
		list: [
			{
				title: 'Hosting',
				data: [
					{
						name: 'Microsoft Azure',
						icon: MicrosoftAzure,
					},
					{
						name: 'AWS',
						icon: AWS,
					},
					{
						name: 'GCP',
						icon: GCP,
					},
				],
			},
		],
	},
	{
		firstText: 'No-code/Low-code',
		secondText: 'Development',
		highlight: 1,
		description:
			'Accelerate your software development with our No-Code/Low-Code services. From rapid prototyping to efficient deployment, we specialize in empowering businesses to build custom applications without extensive coding. ',
		list: [
			{
				title: 'Tools',
				data: [
					{
						name: 'Bubble',
						icon: Bubble,
					},
					{
						name: 'Webflow',
						icon: Webflow,
					},
					{
						name: 'Outsystems',
						icon: Outsystems,
					},
					{
						name: 'Microsoft PowerApps',
						icon: MicrosoftPowerApps,
					},
				],
			},
		],
	},
	{
		firstText: 'ERP',
		secondText: 'Solutions',
		highlight: 1,
		description:
			'Revolutionize your business operations with our extensive ERP solutions. Covering implementation, support, and customization, we excel in tailoring systems to enhance efficiency and productivity. ',
		list: [
			{
				title: 'Tools',
				data: [
					{
						name: 'SAP',
						icon: SAP,
					},
					{
						name: 'Oracle ERP',
						icon: OracleERP,
					},
					{
						name: 'Microsoft D365',
						icon: MicrosoftD365,
					},
					{
						name: 'Netsuite',
						icon: Netsuite,
					},
				],
			},
		],
	},
];

const Technology = () => {
	return (
		<Row className="technology" justify={'center'}>
			<Col span={24}>
				<BannerSection
					isAbout
					title="TECHNOLOGY"
					headText={'Technology Stack'}
					desc={
						'We pride ourselves on being at the forefront of technological advancement, delivering innovative solutions that drive business growth and transformation. With our deep expertise and relentless commitment to excellence, we offer a comprehensive suite of technology capabilities tailored to meet the evolving needs of our clients.'
					}
				/>
			</Col>
			{DATA?.map((data, index) => (
				<TechnologyShowcase
					key={index}
					firstText={data?.firstText}
					secondText={data?.secondText}
					description={data?.description}
					list={data?.list}
					highlight={data?.highlight}
				/>
			))}
			<Col span={20}>
				<p
					className="footer_text"
					style={{
						padding: 0,
					}}>
					Our dedication to staying up-to-date with the latest advancements in technology allows us to deliver innovative solutions that
					empower businesses to thrive in today's digital landscape. Get in touch with us to learn more about how our technology stack can
					drive success for your business
				</p>
			</Col>
			<Col span={24}>
				<Footer />
			</Col>
		</Row>
	);
};

export default Technology;
