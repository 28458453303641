import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const terms = [
    {
      title: "Data Collection",
      description:
        "We collect a variety of information to provide and improve our services",
      subItems: [
        {
          title: "Profile Information",
          description: `Includes your name, email address, phone number, password,
                                 and any other details you provide on your profile. `,
        },
        {
          title: "Service Usage Data",
          description: `Information about your interactions with the platform, such as
                               job applications, messages, posts, and searches.`,
        },
        {
          title: "Connections",
          description: `Data about your connections and interactions with other members `,
        },
        {
          title: "Payment Information",
          description: `Details provided when you purchase premium services or other products.`,
        },
        {
          title: "Device and Location Data",
          description: `Information from the devices you use to access Up-Wyse, such as IP addresses, cookies, and device settings `,
        },
        {
          title: "Third-party Information",
          description: `Data from third-party partners and other Up-Wyse services`,
        },
      ],
    },
    {
      title: "Use of Data",
      description: "We use the collected data for multiple purposes",
      subItems: [
        {
          title: "Providing Services",
          description: `To personalize your user experience, facilitate communication,
                          and connect you with opportunities.`,
        },
        {
          title: "Security",
          description: `To ensure the safety and security of the platform and its users.`,
        },
        {
          title: "Communications",
          description: `To send relevant communications, including promotional
                         messages and updates.`,
        },
        {
          title: "Research and Development",
          description: `To improve our existing services and develop new features.`,
        },
        {
          title: "Advertising",
          description: `To display relevant advertisements and measure the effectiveness of
                            advertising campaigns.`,
        },
      ],
    },
    {
      title: "Data Sharing",
      description: "We may share your data in the following contexts",
      subItems: [
        {
          title: "With Other Members",
          description: `Information on your profile and your interactions on the platform.`,
        },
        {
          title: "Service Providers",
          description: `Companies that perform services on our behalf, such as
                            payment processing, data analysis, and hosting services.`,
        },
        {
          title: "Legal Disclosures",
          description: `When required by law or to protect Up-Wyse's rights and safety.`,
        },
        {
          title: "Business Transfers",
          description: `In the event of a merger, acquisition, or sale of assets.`,
        },
      ],
    },
    {
      title: "User Choices and Obligations",
      description:
        "You have control over your data and can make various choices regarding your privacy",
      subItems: [
        {
          title: "Account Settings",
          description: `Update your profile, preferences, and settings to control what is visible to others.`,
        },
        {
          title: "Data Access and Portability",
          description: `Access your data and request a copy in a structured,
                        commonly used format.`,
        },
        {
          title: "Account Closure",
          description: `Close your account, which will result in the deletion of your
                           profile data from the platform.`,
        },
        {
          title: "Cookie Management",
          description: ` Manage cookie settings through your browser.`,
        },
      ],
    },
    {
      title: "Data Retention",
      description: `Up-Wyse retains user data as long as necessary to provide our services and fulfill the purposes
  described in this Privacy Policy. Data associated with closed accounts is typically deleted
  within 30 days.`,
      subItems: [],
    },
    {
      title: "Security",
      description: `We implement various security measures to protect your data from unauthorized access,
  disclosure, or destruction.`,
      subItems: [],
    },
    {
      title: "Changes to the Privacy Policy",
      description: `We may update our Privacy Policy from time to time. Significant changes will be
  communicated through the platform or via email. The "Effective Date" at the top of this
  policy will be updated to reflect the latest changes.`,
      subItems: [],
    },
    {
      title: "Contact Information",
      description: `If you have any questions or concerns about this Privacy Policy, you can contact us
  through Up-Wyse’s help center or at our designated contact points.`,
      subItems: [],
    },
  ];
  return (
    <div className="privacy">
      <h2>Privacy Policy</h2>
      {terms.map((element) => (
        <div key={element.title}>
          <h6 className="title">{element.title}</h6>
          <span className="description">{element.description}</span>
          <ul>
            {element.subItems?.map((items) => (
              <li key={items.title}>
                <strong>{items.title}: </strong>
                <span className="description">{items.description}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default PrivacyPolicy;
