import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button';
import ClientCentric from '../../assets/svgs/why-choose/clientcentric.svg';
import Fast from '../../assets/svgs/why-choose/fast.svg';
import Industry from '../../assets/svgs/why-choose/industry.svg';
import Innovation from '../../assets/svgs/why-choose/innovation.svg';
import Flexible from '../../assets/svgs/why-choose/flexible.svg';

const CLIENTS = [
	{
		name: 'Industry Experts',
		logo: Industry,
	},
	{
		name: 'Innovation Driven',
		logo: Innovation,
	},
	{
		name: 'Client-Centric',
		logo: ClientCentric,
	},
	{
		name: 'Flexible Engagement',
		logo: Flexible,
	},
	{
		name: 'Fast to Market',
		logo: Fast,
	},
];

const WhyChoose = () => {
	let navigate = useNavigate();
	return (
		<Row className="why_choose">
			<Col sm={24} xl={24}>
				<Row
					justify={'center'}
					align={'middle'}
					gutter={[20, 20]}
					style={{
						margin: 0,
					}}>
					<Col sm={24} xl={16}>
						<h1 className="title">
							Why choose <span>Upwyse</span>
						</h1>
						<p className="desc">
							Embracing flexible engagement models, we tailor our services to match your unique needs and aspirations
						</p>
					</Col>
					<Col span={24} className="feature_container">
						{CLIENTS?.map((client, index) => (
							<div className="container" key={index}>
								<div className="image_container">
									<img src={client.logo} alt={client.name} />
								</div>
								<h3 className="name">{client.name}</h3>
							</div>
						))}
					</Col>
					<Col>
						<Button
							onClick={() => navigate('/about')}
							style={{
								borderRadius: '100px',
								minHeight: '50px',
							}}>
							Know More
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default WhyChoose;
