import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Table, Tabs, Input, Button, notification } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'constants/api-constants';

const { TabPane } = Tabs;
// const { Password } = Input;

const PASSWORD = 'upwyse_admin_password';

const ListComponent = ({ type = 'connects' }) => {
	const [list, setData] = useState({
		data: [],
		loading: false,
	});

	const joinUsColumns = [
		{
			title: 'First Name',
			dataIndex: 'firstName',
			key: 'firstName',
		},
		{
			title: 'Last Name',
			dataIndex: 'lastName',
			key: 'lastName',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: 'LinkedIn',
			dataIndex: 'linkedInProfile',
			key: 'linkedInProfile',
			render: (text) => (
				<a
					style={{
						textDecoration: 'underline',
						color: '#3f4ff8',
					}}
					href={text}
					target="_blank"
					rel="noreferrer">
					Link
				</a>
			),
		},
		{
			title: 'Job Title',
			dataIndex: 'jobTitle',
			key: 'jobTitle',
		},
		{
			title: 'Total Experience',
			dataIndex: 'totalExperience',
			key: 'totalExperience',
		},
		{
			title: 'Employment Type',
			dataIndex: 'employmentType',
			key: 'employmentType',
		},
	];

	const connectColumns = [
		{
			title: 'First Name',
			dataIndex: 'firstName',
			key: 'firstName',
		},
		{
			title: 'Last Name',
			dataIndex: 'lastName',
			key: 'lastName',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: 'Message',
			dataIndex: 'message',
			key: 'message',
		},
		{
			title: 'Service Needed',
			dataIndex: 'serviceNeeded',
			key: 'serviceNeeded',
		},
	];

	const getData = useCallback(async () => {
		try {
			setData((list) => ({ ...list, loading: true }));
			const res = await axios.get(`${BASE_URL}/${type === 'team_members' ? 'team_members' : 'connects'}`);
			setData((list) => ({ ...list, data: res?.data || [], loading: false }));
		} catch (error) {
			console.error('Error fetching data:', error);
			setData({ ...list, loading: false });
		}
	}, [list, setData, type]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);

	return <Table dataSource={list?.data || []} loading={list?.loading} columns={type === 'team_members' ? joinUsColumns : connectColumns} />;
};

const Admin = () => {
	const [password, setPassword] = useState('');
	const [authenticated, setAuthenticated] = useState(false);

	const handlePasswordSubmit = () => {
		// Replace 'your_password' with your actual password
		if (password === PASSWORD) {
			setAuthenticated(true);
		} else {
			notification.error({
				message: 'Incorrect Password',
				description: 'Please enter the correct password to access the content.',
			});
		}
	};

	return (
		<Row style={{ padding: 20 }}>
			{!authenticated ? (
				<Col span={8} offset={8}>
					<Input.Password
						placeholder="Enter password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						onPressEnter={handlePasswordSubmit}
					/>
					<Button type="primary" onClick={handlePasswordSubmit} style={{ marginTop: '10px' }}>
						Submit
					</Button>
				</Col>
			) : (
				<Col span={24}>
					<Tabs defaultActiveKey="1" destroyInactiveTabPane>
						<TabPane tab="Join Us" key="1">
							<ListComponent type="team_members" />
						</TabPane>
						<TabPane tab="Contact Us" key="2">
							<ListComponent />
						</TabPane>
					</Tabs>
				</Col>
			)}
		</Row>
	);
};

export default Admin;
