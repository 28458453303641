import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { MenuItems } from './MenuItems';
import './Dropdown.scss';

export const MenuList1 = [
	{
		title: 'AI/ML',
		path: '/ai_ml',
	},
	{ title: 'RPA (Robotic Process Automation)', path: '/rpa' },
	{
		title: 'IoT (Internet of Things)',
		path: '/iot',
	},
];

export const MenuList2 = [
	{
		title: 'No-code & Low code development',
		path: '/no-code-low-code-development',
	},
	{
		title: 'Full stack engineering',
		path: '/full-stack-engineering',
	},
	{ title: 'ERP Solutions', path: '/erp-solutions' },
];

export const MenuList3 = [
	{
		title: 'Content Management System',
		path: '/content-management-system',
	},
	{
		title: 'SEO/Digital Marketing',
		path: '/seo-digital-marketing',
	},
	{
		title: 'E-commerce solutions',
		path: '/e-commerce-solutions',
	},
];

function Dropdown({ closeMenu, activeSubMenuItem, setSubActiveMenuItem }) {
	const [click, setClick] = useState(false);

	const handleClick = () => setClick(!click);

	const handleMenuItemClick = (menuItem) => {
		setSubActiveMenuItem(menuItem);
		closeMenu();
		setClick(false);
	};

	return (
		<div className={click ? 'dropdown-menu clicked' : 'dropdown-menu'} onMouseLeave={closeMenu}>
			<ul onClick={handleClick}>
				{MenuList1.map((item, index) => {
					return (
						<li key={index} className={`${activeSubMenuItem === item.title ? 'submenu-clicked' : ''}`}>
							<Link to={`services${item.path}`} className="dropdown-link" onClick={() => handleMenuItemClick(item.title)}>
								{item.title}
							</Link>
						</li>
					);
				})}
			</ul>
			<ul onClick={handleClick}>
				{MenuList2.map((item, index) => {
					return (
						<li key={index} className={`${activeSubMenuItem === item.title ? 'submenu-clicked' : ''}`}>
							<Link className={'dropdown-link'} to={`services${item.path}`} onClick={() => handleMenuItemClick(item.title)}>
								{item.title}
							</Link>
						</li>
					);
				})}
			</ul>
			<ul onClick={handleClick}>
				{MenuList3.map((item, index) => {
					return (
						<li key={index} className={`${activeSubMenuItem === item.title ? 'submenu-clicked' : ''}`}>
							<Link className={'dropdown-link'} to={`services${item.path}`} onClick={() => handleMenuItemClick(item.title)}>
								{item.title}
							</Link>
						</li>
					);
				})}
			</ul>
		</div>
	);
}

export default Dropdown;
